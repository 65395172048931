import React from "react";
import styles from "./TextInput.module.scss";

export const TextInput = ({
  register,
  name,
  validation,
  errors,
  defaultValue,
  placeholder,
  type = "text",
}) => {
  const hasError = errors && !!errors.errors[name];

  return (
    <div className={styles.textInput}>
      <input
        type={type}
        aria-describedby={name}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(name, validation)}
      />
      <span style={{ opacity: `${hasError ? 1 : 0}` }}>
        {errors.errors[name]?.message ?? "Please complete this required field."}
      </span>
    </div>
  );
};
