import uuid from 'uuid';
import database from '../firebase/firebase';
import { realQuant, findDataFromId } from '../components/ProductData';
// import Geocode from 'react-geocode';

// Geocode.setApiKey(process.env.REACT_APP_GEOCODE_API);

export const addToCart = (obj) => ({
  type: 'ADD_TO_CART',
  obj
});

export const startAddToCart = (obj = {}) => (dispatch) => {
  dispatch(addToCart(obj));
};

export const setQuantity = (obj) => ({
  type: 'SET_QUANTITY',
  obj
});

export const startSetQuantity = (obj = {}) => (dispatch) => {
  dispatch(setQuantity(obj));
};

export const deleteItem = (id) => ({
  type: 'DELETE_ITEM',
  id
});

export const startDeleteItem = (id) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(deleteItem(id));
    resolve();
  });

export const addGiftToCart = (gift) => ({
  type: 'ADD_GIFT_TO_CART',
  gift
});

export const startAddGiftToCart = (gift) => (dispatch) => {
  const randomId = uuid.v4();
  dispatch(
    addGiftToCart({
      id: randomId,
      ...gift
    })
  );
};

export const removeGift = ({ id } = {}) => ({
  type: 'REMOVE_GIFT',
  id
});

export const startRemoveGift = ({ id } = {}) => (dispatch) => {
  dispatch(removeGift({ id }));
};

// to firebase newsletter
export const subscribe = (email) => ({
  type: 'SUBSCRIBE',
  email
});

export const startSubscribe = (email) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(subscribe(email));
    resolve();
  });

export const removeAddress = () => ({
  type: 'REMOVE_ADDRESS'
});

export const startRemoveAddress = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch(removeAddress());
    resolve();
  });

export const sendOrder = (planData) => ({
  type: 'SEND_ORDER',
  planData
});

// insert new order into a user's firebase id
export const startSendOrder = (planData) => (dispatch, getState) =>
  new Promise((resolve) => {
    const { uid } = getState().auth;

    const array = planData.cart;
    const objVariety = {};

    array.forEach((item) => {
      // const stringVar = findDataFromId(item.id);
      objVariety[item.id] = item.quantity;
    });

    const finalOrderObj = {
      totalCost: planData.amount,
      numberTotalPlants: realQuant(planData.cart),
      variety: objVariety,
      user: uid
    };

    const finalOrderObjUser = {
      totalCost: planData.amount,
      numberTotalPlants: realQuant(planData.cart),
      variety: objVariety
    };
    // Everything that should be pushed in orders is pushed
    const orderKey = database.ref('orders').push(finalOrderObj);
    // console.log(orderKey)

    let rcBool;
    let rc;
    const remainingCredsBool = database.ref(
      `users/google/${uid}/remainingCreds`
    );
    remainingCredsBool.on('value', (snapshot) => {
      if (snapshot.exists()) {
        rcBool = true;
        rc = snapshot.val();
      } else {
        rcBool = false;
      }
    });

    if (rcBool) {
      // if remainingCreds exists
      rc += realQuant(planData.cart);
    } else {
      rc = realQuant(planData.cart);
    }

    let tpBool;
    let tp;
    const totalPurchasedBool = database.ref(
      `users/google/${uid}/totalPurchased`
    );

    totalPurchasedBool.on('value', (snapshot) => {
      if (snapshot.exists()) {
        tpBool = true;
        tp = snapshot.val();
      } else {
        tpBool = false;
      }
    });

    if (tpBool) {
      // if totalPurchased exists
      tp += realQuant(planData.cart);
    } else {
      tp = realQuant(planData.cart);
    }

    let upBool;
    const unusedPlantsBool = database.ref(`users/google/${uid}`);
    unusedPlantsBool
      .child('unusedPlants')
      .once('value', (snapshot) => {
        if (snapshot.exists()) {
          upBool = true;
        } else {
          upBool = false;
        }
      })
      .then(() => {
        const specPlant = database.ref(`users/google/${uid}/unusedPlants`);
        const spec = {};
        if (upBool) {
          array.forEach((item) => {
            const stringVar = findDataFromId(item.id);

            specPlant
              .child(stringVar)
              .once('value', (snapshot) => {
                if (snapshot.exists()) {
                  spec[stringVar] = item.quantity + snapshot.val();
                } else {
                  spec[stringVar] = item.quantity;
                }
              })
              .then(() => {
                database.ref(`users/google/${uid}/unusedPlants`).update(spec);
              });
          });
        } else {
          database.ref(`users/google/${uid}/unusedPlants`).update(objVariety);
        }
      });

    const orderObj = {};
    orderObj[orderKey.key] = finalOrderObjUser;

    database
      .ref(`users/google/${uid}/account`)
      .update({ email: planData.email })
      .then(() => {
        database
          .ref(`users/google/${uid}/orders`)
          .update(orderObj)
          .then(() => {
            database
              .ref(`users/google/${uid}`)
              .update({ remainingCreds: rc })
              .then(() => {
                database
                  .ref(`users/google/${uid}`)
                  .update({ totalPurchased: tp })
                  .then(() => {
                    dispatch(sendOrder(planData));
                    resolve();
                  });
              });
          });
      });
  });

// for closing the shopping cart sliding pane
export const paneFalse = () => ({
  type: 'SET_PANE_FALSE'
});

export const startPaneFalse = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch(paneFalse());
    resolve();
  });

// for opening the shopping cart sliding pane
export const paneTrue = () => ({
  type: 'SET_PANE_TRUE'
});

export const startPaneTrue = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch(paneTrue());
    resolve();
  });

export const setVerifiedFalse = () => ({
  type: 'SET_VERIFIED_FALSE'
});

export const startSetVerifiedFalse = () => (dispatch) => {
  dispatch(setVerifiedFalse());
};

export const validateAddress = (allInfo) => ({
  type: 'VALIDATE_ADDRESS',
  allInfo
});

export const startValidateAddress = (obj) => (dispatch) =>
  new Promise((resolve) => {
    dispatch(validateAddress(obj));
    resolve();
  });

export const validate = (token) => ({
  type: 'VALIDATE',
  token
});

export const startValidate = (token) => (dispatch) =>
  new Promise((resolve) => {
    database
      .ref('tax')
      .once('value')
      .then((snapshot) => {
        const dict = snapshot.val();
        const searchZip = token.zip;
        let val = dict[parseInt(searchZip)];
        if (!val) {
          val = 0.07;
        }
        const numAmount = parseInt(token.amount);
        const totalTax = numAmount * val + numAmount;
        token.amount = totalTax.toFixed(2);
        token.tax = numAmount * val;
        dispatch(validate(token));
        resolve();
      });
  });

export const selectAddress = (address) => {
  return {
    type: 'ADDRESS_SELECTED',
    payload: address
  };
};
