import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion/dist/framer-motion";
import { Icon } from "../../Common";
import styles from "./Accordion.module.scss";

export const AccordionItem = ({ title, children, isActive, handleOpen }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isActive);
  }, [isActive]);

  const handleTitleClick = () => {
    setIsOpen(!isOpen);
    if (handleOpen) handleOpen();
  };

  return (
    <div className={styles.accordionItem} id={title}>
      <div onClick={handleTitleClick} className={styles.accordionItem__header}>
        <h3 className={styles.accordionItem__title}>{title}</h3>
        <Icon type={isOpen ? "minus" : "plus"} />
      </div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "initial" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className={styles.accordionItem__content}>{children}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
