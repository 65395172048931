// const firebase = require('firebase/app');
import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/database';
import 'firebase/analytics';

// require("dotenv").config({ path: "/" });

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJ_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
  firebaseId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_APP_MEASURMENT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// eslint-disable-next-line no-console
// console.log(firebase);

firebase.initializeApp(firebaseConfig);

firebase.analytics();

const database = firebase.database();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export {
  firebase,
  googleAuthProvider,
  facebookAuthProvider,
  database as default,
};
