import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export const Image = ({
  className,
  src,
  alt,
  width = "100%",
  height = "100%",
}) => {
  return (
    <div className={className}>
      <LazyLoadImage
        alt={alt}
        effect="blur"
        src={src}
        width={width}
        height={height}
        threshold={200}
        style={{ verticalAlign: "bottom" }}
      />
    </div>
  );
};
