import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "../reducers/auth";
import cartReducer from "../reducers/cart";
import productsReducer from "../reducers/products";
import inventoryReducer from "../reducers/inventory";
import analysisModalReducer from "../reducers/analysisModal";
import plantInfoReducer from "../reducers/plantInfo";
import orderNumReducer from "../reducers/orderNum";
import checkoutMetadataReducer from "../reducers/checkoutMetadata";
import analysisInfoReducer from "../reducers/analysisInfo";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const APP_VERSION = "1.0.3";
let localAppVersion = JSON.parse(localStorage.getItem("appVersion"));
if (localAppVersion === null || localAppVersion !== APP_VERSION) {
  localStorage.removeItem("plantInfo");
  localStorage.setItem("appVersion", JSON.stringify(APP_VERSION));
}

let cartInit = JSON.parse(localStorage.getItem("cart"));
// console.log('localStorage', localStorage);
if (cartInit === null) {
  cartInit = {
    cartObj: {
      cartObjName: "",
      amount: 0,
    },
    token: {
      id: "",
      name: "",
      desc: "",
      amount: 0,
      zip: "",
      tax: 0,
    },
    temp: {
      deliv_address: "",
      cart: [],
      subtotal: 0,
      taxtotal: 0,
      delivery: 0,
      total: 0,
    },
    gifts: [],
    cart: [],
    plantInformation: null,
    verified: false,
    checked_out: false,
    address: "",
    cartOpen: false,
  };
} else {
  // cartInit.plantInformation = null;
  cartInit.verified = false;
  cartInit.checked_out = false;
  cartInit.cartOpen = false;
}

let analysisModalInit = JSON.parse(localStorage.getItem("analysisModal"));
// console.log(analysisModalInit);
if (analysisModalInit === null) {
  analysisModalInit = false;
}

let analysisInfoInit = JSON.parse(localStorage.getItem("analysisInfo"));
// console.log("store", analysisInfoInit);
if (analysisInfoInit === null) {
  analysisInfoInit = { lat: 40.7, lon: -73.99 };
}

const initialState = {
  // auth: localStorage.getItem('auth'),
  cart: cartInit,
  analysisModal: analysisModalInit,
  plantInfo: JSON.parse(localStorage.getItem("plantInfo")),
  analysisInfo: analysisInfoInit,
};

export default () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      cart: cartReducer,
      products: productsReducer,
      analysisModal: analysisModalReducer,
      plantInfo: plantInfoReducer,
      orderNum: orderNumReducer,
      checkoutMetadata: checkoutMetadataReducer,
      analysisInfo: analysisInfoReducer,
      inventory: inventoryReducer,
      appVersion: localAppVersion,
    }),
    initialState,
    composeEnhancers(applyMiddleware(thunk))
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  return store;
};

// Store creation
