const cartReducerDefaultState = {
  cartObj: {
    // not needed right now
    cartObjName: '',
    amount: 0
  },
  token: {
    id: '',
    name: '',
    desc: '',
    amount: 0,
    zip: '',
    tax: 0
  },
  temp: {
    deliv_address: '',
    cart: [],
    subtotal: 0,
    taxtotal: 0,
    delivery: 0,
    total: 0
  },
  gifts: [], // not used for now
  cart: [], // state keeping track of what's in the user's cart currently
  plantInformation: null, // info from Nutch
  verified: false, // credit card is valid and verified
  checked_out: false, // already placed order
  address: '',
  cartOpen: false // for global modal
};

export default (state = cartReducerDefaultState, action) => {
  switch (action.type) {
    case 'REMOVE_ADDRESS':
      return {
        ...state,
        address: ''
      };
    case 'SET_PANE_FALSE':
      return {
        ...state,
        cartOpen: false
      };
    case 'SET_PANE_TRUE':
      return {
        ...state,
        cartOpen: true
      };
    case 'SET_VERIFIED_FALSE':
      return {
        ...state,
        verified: false
      };
    case 'ADD_TO_CART':
      // if item is in cart
      const itemExist = state.cart.find((item) => action.obj.id === item.id);
      if (itemExist) {
        const deepCopy = JSON.parse(JSON.stringify(itemExist));
        deepCopy.quantity += action.obj.quantity;
        const newArray = state.cart.filter((item) => action.obj.id !== item.id);
        const cart = [...newArray, deepCopy];
        // cart.sort((a, b) => a.id - b.id);
        cart.sort((a, b) => ('' + a.id).localeCompare(b.id));
        return {
          ...state,
          cart,
          cartOpen: true
        };
      }
      // object doesn't exist, so make new
      // const addedItem = state.items.find((item) => item.id === action.obj.id);
      // addedItem.quantity = action.obj.quantity;
      const addedItem = action.obj;
      const newArray2 = state.cart.filter((item) => action.obj.id !== item.id);
      const cart2 = [...newArray2, addedItem];
      // cart2.sort((a, b) => a.id - b.id);
      cart2.sort((a, b) => ('' + a.id).localeCompare(b.id));
      return {
        ...state,
        cart: cart2,
        cartOpen: true
      };

    case 'SET_QUANTITY':
      const newItems = state.cart.filter((item) => action.obj.id !== item.id);
      const existedItem2 = state.cart.find((item) => action.obj.id === item.id);

      // const addedItem2 = state.items.find((item) => item.id === action.obj.id);
      // const addedItem2Copy = JSON.parse(JSON.stringify(addedItem2));
      if (existedItem2) {
        // addedItem2Copy.quantity = action.obj.quantity;

        const cart = [...newItems, action.obj];
        cart.sort((a, b) => ('' + a.id).localeCompare(b.id));
        return {
          ...state,
          cart
        };
      }
      // addedItem2Copy.quantity = action.obj.quantity;

      const cart = [...state.cart, action.obj];
      // cart.sort((a, b) => a.id - b.id);
      cart.sort((a, b) => ('' + a.id).localeCompare(b.id));
      return {
        ...state,
        cart
      };

    case 'DELETE_ITEM':
      const newCart = state.cart.filter((item) => action.id !== item.id);
      return {
        ...state,
        cart: newCart
      };

    case 'ADD_GIFT_TO_CART':
      const currGifts = state.gifts;
      currGifts.push(action.gift);
      return { ...state, gifts: currGifts };
    case 'REMOVE_GIFT':
      const newGifts = state.gifts.filter(({ id }) => id !== action.id);
      return { ...state, gifts: newGifts };
    case 'SEND_ORDER':
      const tempData = state.temp;
      const tempCopy = JSON.parse(JSON.stringify(tempData));
      tempCopy.cart = action.planData.cart;
      tempCopy.subtotal = action.planData.subtotal;
      tempCopy.taxtotal = action.planData.taxtotal;
      tempCopy.delivery = action.planData.delivery;
      tempCopy.total = action.planData.total;
      return {
        ...state,
        cart: [],
        token: {
          id: '',
          name: '',
          desc: '',
          amount: 0,
          zip: '',
          tax: 0
        },
        checked_out: true,
        temp: tempCopy
      };
    case 'VALIDATE': // credit card info
      const addressObj = {
        deliv_address: `${action.token.address} ${action.token.city} ${action.token.state} ${action.token.zip}`,
        cart: [],
        subtotal: 0,
        taxtotal: 0,
        delivery: 0,
        total: 0
      };
      const addressCopy = JSON.parse(JSON.stringify(addressObj));
      return {
        ...state,
        token: action.token,
        verified: true,
        temp: addressCopy
      };
    case 'VALIDATE_ADDRESS':
      return {
        ...state,
        address: action.allInfo.address,
        plantInformation: action.allInfo.plantInformation
      };
    case 'ADDRESS_SELECTED':
      return { ...state, address: action.payload };
    default:
      return state;
  }
};
