import React from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { isEmpty } from "../../../utils/isEmpty";
import SlidingPane from "react-sliding-pane";
import { Row, Col, Spinner, Dropdown } from "react-bootstrap";

import FunctionModal from "../../FunctionModal";
import { firebase } from "../../../firebase/firebase";
import { totalCostCalc, realQuant } from "../../ProductData";
import {
  startSetQuantity,
  startDeleteItem,
  startPaneFalse,
  startPaneTrue,
} from "../../../actions/cart";
import { startLogout, startLogin } from "../../../actions/auth";

import "react-sliding-pane/dist/react-sliding-pane.css";

import "../../../styles/components/_landing.css";
import "../../../styles/components/header.css";
import "../../../styles/components/_productshowcase.css";
import "../../../styles/components/_slide-pane.scss";

class HeaderComponent extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      hasCheckedLogin: false,
      user: null,
      cartQuant: realQuant(this.props.cart),
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    this._isMounted = true;
    firebase.auth().onAuthStateChanged((user) => {
      if (this._isMounted) {
        if (user) {
          this.setState({ user, hasCheckedLogin: true });
        } else {
          this.setState({ user: null, hasCheckedLogin: true });
        }
      }
    });
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.cartQuant !== realQuant(this.props.cart)) {
      this.setState({ cartQuant: realQuant(this.props.cart) });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  onQuantSelect = (amount, item) => {
    // e.preventDefault;
    const intQuant = amount;
    const plantInventoryAvailable =
      this.props?.inventory[item.shopify_variant_id];
    if (
      (plantInventoryAvailable === undefined ||
        intQuant > plantInventoryAvailable) &&
      item.growSquareCount === undefined // if it is a plant
    ) {
      return;
    }
    const productObj = {
      id: item.id,
      quantity: intQuant,
      shopify_variant_id: item.shopify_variant_id,
      growSquareCount: item.growSquareCount,
      productCustomTitle: item.productCustomTitle,
      productCustomSubTitle: item.productCustomSubTitle,
    };
    this.props.startSetQuantity(productObj);
  };

  onDeleteItem = (e, id) => {
    e.preventDefault();
    this.props.startDeleteItem(id);
  };

  checkout = (e) => {
    e.preventDefault();
    if (this.props.cart.length > 0) {
      this.props.history.push("/");
    }
  };

  conditionalCart = (e) => {
    e.preventDefault();
    const urlPath = this.props.history.location.pathname.substring(1, 11);
    if (urlPath !== "getstarted") {
      this.props.startPaneTrue();
    }
  };

  cartQuant() {
    return realQuant(this.props.cart);
  }

  loggingIn(e) {
    e.preventDefault();
    this.props.startLogin();
  }

  loggingOut(e) {
    e.preventDefault();
    this.props.startLogout();
  }

  displayOverflowMenu() {
    const overflowMenu = document.querySelector(".overflow-menu-wrapper");
    overflowMenu.classList.toggle("is-hidden");
    // Switch the hamburger menu to a cross when clicked
    const hamburgerIcon = document.querySelector(".hamburger-menu-icon");
    if (hamburgerIcon.src.includes("/images/header/cross.svg")) {
      hamburgerIcon.src = "/images/header/square.svg";
    } else {
      hamburgerIcon.src = "/images/header/cross.svg";
    }
  }

  renderSigninOrLogout = () => {
    if (!this.state.user) {
      return (
        <div
          className="account-btn-wrapper px-5"
          onClick={(e) => this.loggingIn(e)}
          style={{ cursor: "pointer" }}
        >
          <img
            src="/images/icons/header-account.svg"
            alt="account sign in or sign out button"
            className="navbar-logo"
            style={{ transform: "scale(0.85)" }}
          />
        </div>
      );
    } else {
      const userImageUrl = firebase.auth().currentUser.photoURL;
      return (
        <div
          className="account-btn-wrapper px-3"
          onClick={(e) => this.loggingOut(e)}
        >
          <img
            src={userImageUrl}
            alt="account sign in or sign out button"
            className="navbar-logo user-account-btn"
          />
        </div>
      );
    }
  };

  renderMobileHeader = () => {
    return (
      <div
        className="d-flex flex-column fixed-top"
        style={{
          backgroundColor: "#fff",
          boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.08)",
        }}
      >
        <div className="header-wrapper-mobile d-flex flex-row justify-content-between align-items-center px-3">
          <div className="left-wrapper d-flex flex-row align-items-center">
            <div className="pr-2" onClick={() => this.displayOverflowMenu()}>
              <img
                src="/images/header/square.svg"
                className="navbar-logo hamburger-menu-icon"
                alt="hamburger"
              />
            </div>
          </div>
          <div className="d-flex flex-row flex-grow-1 justify-content-center align-items-center">
            <Link to="/">
              <img
                alt="navbar-logo"
                src="https://storage.googleapis.com/growsquares_website_assets/landing/GSQ_LOGO.svg"
                className="navbar-logo"
                style={{
                  height: "20px",
                  width: "auto",
                }}
              />
            </Link>
          </div>
          <div
            className="add-to-cart-wrapper"
            onClick={(e) => this.conditionalCart(e)}
          >
            <img
              src="/images/icons/header-cart.svg"
              alt="shopping cart button"
              className="navbar-logo"
              style={{
                width: "25px",
                height: "auto",
                transform: "scale(0.85)",
              }}
            />
            <span
              style={{ display: this.cartQuant() === 0 ? "none" : "" }}
              className="cart-quantity-num"
            >
              {this.cartQuant()}
            </span>
          </div>
        </div>
        <div className="overflow-menu-wrapper is-hidden">
          <div className="inner-wrapper d-flex flex-column px-3">
            <div className="link-wrapper">
              <Link to="/products" className="navlink-mobile w-100">
                Shop
              </Link>
            </div>
            <div
              className="dropdown-wrapper d-flex flex-column"
              style={{ borderBottom: "1px solid #ebeaea" }}
            >
              <div
                className="navlink-mobile w-100 d-flex flex-row justify-content-between learn-dropdown-btn"
                onClick={() => {
                  const linksDropdown =
                    document.querySelector(".links-dropdown");
                  if (
                    linksDropdown.classList.toString().includes("is-hidden")
                  ) {
                    linksDropdown.classList.toggle("is-hidden");
                    linksDropdown.classList =
                      "links-dropdown d-flex flex-column";
                  } else {
                    linksDropdown.classList = "links-dropdown is-hidden";
                  }
                }}
              >
                <span>Learn</span>
                <img
                  src="/images/header/arrow_down.svg"
                  width="10px"
                  alt="arrow-down"
                />
              </div>
              <div className="links-dropdown is-hidden">
                <Link to="/square" className="navlink-mobile">
                  The Square
                </Link>
                <Link to="/features" className="navlink-mobile">
                  Features
                </Link>
                <Link to="/ourtech" className="navlink-mobile">
                  Our Technology
                </Link>
                <Link to="/about-us" className="navlink-mobile">
                  About Us
                </Link>
                <a
                  href="https://apply.workable.com/growsquares/"
                  className="navlink-mobile"
                  target="_blank"
                  rel="noreferrer"
                >
                  Careers
                </a>
                <Link to="/faq" className="navlink-mobile">
                  FAQs
                </Link>
              </div>
            </div>
            <div className="buttons-section d-flex flex-row">
              <div className="account-section-wrapper">
                {/* Render user image if they are logged in */}
                {this.state.user && (
                  <div className="account-btn-wrapper">
                    <img
                      src={firebase.auth().currentUser.photoURL}
                      alt="account sign in or sign out button"
                      className="navbar-logo user-account-btn"
                    />
                  </div>
                )}
                {!this.state.user && (
                  <div className="account-btn-wrapper">
                    <img
                      src="/images/icons/header-account.svg"
                      alt="account sign in or sign out button"
                      className="navbar-logo user-account-btn"
                      style={{ transform: "scale(0.85)" }}
                    />
                  </div>
                )}
                {this.state.user && (
                  <button
                    className="button-type-2 logout-btn"
                    onClick={(e) => this.loggingOut(e)}
                  >
                    Log Out
                  </button>
                )}
                {!this.state.user && (
                  <button
                    className="button-type-2"
                    onClick={(e) => this.loggingIn(e)}
                  >
                    Sign In
                  </button>
                )}
              </div>
              <div className="analysis-mobile-wrapper">
                <Link to="/analysis1">
                  <button
                    className="button-type-2 start-analysis-btn-mobile"
                    style={{
                      borderBottom: "2px solid",
                      paddingLeft: "0px",
                      paddingRight: "0px",
                    }}
                    type="button"
                    name="button"
                  >
                    Start Analysis
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderDesktopHeader = () => {
    return (
      <div
        className="header-wrapper d-flex flex-row px-5 align-items-center fixed-top"
        style={{
          height: "75px",
          backgroundColor: "#fff",
          boxShadow: "0px 0px 35px rgba(0, 0, 0, 0.08)",
        }}
      >
        <div className="links-wrapper">
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-shop">
              Shop
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/products" className="p-3">
                <div className="navlink-desktop">Products</div>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/gifting" className="p-3">
                <div className="navlink-desktop">Gifting</div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-btn-navbar">
              Learn
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item as={Link} to="/square" className="p-3">
                <div className="navlink-desktop">The Square</div>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/features" className="p-3">
                <div className="navlink-desktop">Features</div>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/ourtech" className="p-3">
                <div className="navlink-desktop">Our Technology</div>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/about-us" className="p-3">
                <div className="navlink-desktop">About Us</div>
              </Dropdown.Item>
              <Dropdown.Item
                tag="a"
                href="https://apply.workable.com/growsquares/"
                className="p-3"
                target="_blank"
              >
                <div className="navlink-desktop">Careers</div>
              </Dropdown.Item>
              <Dropdown.Item as={Link} to="/faq" className="p-3">
                <div className="navlink-desktop">FAQs</div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="hero-img-wrapper">
          <Link to="/">
            <img
              src="https://storage.googleapis.com/growsquares_website_assets/landing/GSQ_LOGO.svg"
              className="navbar-logo"
              alt="navbar-logo"
            />
          </Link>
        </div>
        <div className="right-side-wrapper">
          <Link to="/analysis1">
            <button className="header-button" type="button" name="button">
              Start Analysis
            </button>
          </Link>
          {this.renderSigninOrLogout()}
          <div
            className="add-to-cart-wrapper"
            onClick={(e) => this.conditionalCart(e)}
            style={{ cursor: "pointer" }}
          >
            <img
              src="/images/icons/header-cart.svg"
              alt="shopping cart button"
              className="navbar-logo"
              style={{ transform: "scale(0.85)" }}
            />
            <span
              style={{ display: this.cartQuant() === 0 ? "none" : "" }}
              className="cart-quantity-num"
            >
              {this.cartQuant()}
            </span>
          </div>
        </div>
      </div>
    );
  };

  ShoppingCartCard = (item, ind) => {
    let price = 0;
    if (item.growSquareCount) {
      price = item.quantity * item.growSquareCount * 25;
    } else {
      price = item.quantity * 25;
    }

    let productTitle = "";
    if (item.productCustomTitle) {
      productTitle = item.productCustomTitle;
    } else {
      if (this.props.products[item.id.replace(/[0-9]/g, "")])
        productTitle = this.props.products[item.id.replace(/[0-9]/g, "")].name;
    }

    let productSubTitle = "";
    if (item.productCustomSubTitle) {
      productSubTitle = item.productCustomSubTitle;
    } else {
      if (this.props.products[item.id.replace(/[0-9]/g, "")])
        productSubTitle =
          this.props.products[item.id.replace(/[0-9]/g, "")].varieties[item.id]
            .name;
    }

    const plantInventoryAvailable =
      this.props.inventory?.[item.shopify_variant_id];
    if (
      plantInventoryAvailable === undefined &&
      item.growSquareCount === undefined
    ) {
      // if it's a plant
      // don't render the plant till we fetch the inventory
      return;
    } else if (plantInventoryAvailable === 0) {
      // delete item from the cart
      this.props.startDeleteItem(item.id);
    }
    return (
      <div key={ind}>
        {/* <div key={ind}>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: this.state.width > 600 ? "5%" : "5% 7%",
              paddingLeft: this.state.width > 600 ? "5%" : "0",
              margin: this.state.width > 600 ? "0 -15px" : "0%",
            }}
          >
            <Col xs={3}>
              <img
                alt=""
                style={{ width: "100%" }}
                src={`/images/productpage/${item.id}.png`}
              />
            </Col>
            <Col
              xs={6}
              className="d-flex flex-column justify-content-between"
            >
              <div>
                <div className="plant-title">
                  {
                    this.props.products[item.id.replace(/[0-9]/g, "")]
                      .name
                  }
                </div>
                <div className="plant-title light-grey-plant-variety">
                  {
                    this.props.products[item.id.replace(/[0-9]/g, "")]
                      .varieties[item.id].name
                  }
                </div>
              </div>
              <div className="d-flex flex-row">
                <span className="green d-flex flex-row align-items-center pr-2">
                  QTY
                </span>
                <div
                  className="quantity-changer d-flex flex-row justify-content-between align-items-center"
                  style={{
                    color: "#00807B",
                    borderRadius: "2px",
                    // border: "1px solid #d8d8d8",
                  }}
                >
                  <button
                    type="button"
                    onClick={() =>
                      this.onQuantSelect(
                        Math.max(item.quantity - 1, 1),
                        item.id,
                        item.shopify_variant_id
                      )
                    }
                    className=""
                    style={{
                      fontSize: "14px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#31343D",
                      backgroundColor: "rgb(249, 249, 249",
                    }}
                  >
                    -
                  </button>
                  <div
                    className=" px-3"
                    style={{
                      fontSize: "14px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      backgroundColor: "#f9f9f9",
                      color: "#31343D",
                    }}
                  >
                    {item.quantity}
                  </div>
                  <button
                    type="button"
                    onClick={() =>
                      this.onQuantSelect(
                        item.quantity + 1,
                        item.id,
                        item.shopify_variant_id
                      )
                    }
                    className=""
                    style={{
                      fontSize: "14px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#31343D",
                      backgroundColor: "rgb(249, 249, 249",
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </Col>
            <Col
              xs={3}
              className="d-flex flex-column justify-content-between"
            >
              <button
                onClick={(e) => this.onDeleteItem(e, item.id)}
                style={{
                  fontSize: "14px",
                  // position: "absolute",
                  // bottom: "10%",
                  // right: "20%",
                  backgroundColor: "white",
                }}
              >
                Remove
              </button>
              <div className="text-center green">
                ${(item.quantity * 25).toString()}
                .00
              </div>
            </Col>
          </Row>
        </div>
         */}
        <div className="cart-card-container">
          <div>
            <img
              alt=""
              style={{ width: "100%" }}
              src={
                item.growSquareCount
                  ? "/images/icons/giftcard-cart.svg"
                  : `/images/productpage/${item.id}_shop.png`
              }
            />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "100%",
              }}
            >
              <div>
                <div
                  className="plant-title"
                  style={{
                    marginBottom: "3px",
                    fontFamily: "Poppins",
                    lineHeight: "20px",
                  }}
                >
                  {productTitle}
                </div>
                <div className="plant-title light-grey-plant-variety">
                  {productSubTitle}
                </div>
              </div>

              <div style={{ display: "flex" }}>
                <span className="green d-flex flex-row align-items-center pr-2">
                  QTY
                </span>
                <div
                  className="quantity-changer d-flex flex-row justify-content-between align-items-center"
                  style={{
                    color: "#00807B",
                    borderRadius: "2px",
                    // border: "1px solid #d8d8d8",
                  }}
                >
                  <button
                    type="button"
                    onClick={() =>
                      this.onQuantSelect(Math.max(item.quantity - 1, 1), item)
                    }
                    className=""
                    style={{
                      fontSize: "14px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#31343D",
                      backgroundColor: "rgb(249, 249, 249",
                    }}
                  >
                    -
                  </button>
                  <div
                    className="item-quantity"
                    style={{
                      fontSize: "14px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      backgroundColor: "#f9f9f9",
                      color: "#31343D",
                    }}
                  >
                    {item.quantity}
                  </div>
                  <button
                    type="button"
                    onClick={() => this.onQuantSelect(item.quantity + 1, item)}
                    className=""
                    style={{
                      fontSize: "14px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      color: "#31343D",
                      backgroundColor: "rgb(249, 249, 249",
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <button
              onClick={(e) => this.onDeleteItem(e, item.id)}
              style={{
                fontSize: "14px",
                // position: "absolute",
                // bottom: "10%",
                // right: "20%",
                backgroundColor: "white",
              }}
            >
              {/* Remove */}
              <img
                alt="close icon"
                style={{ width: "42%" }}
                className=""
                src="/images/icons/close_x.svg"
              />
            </button>
            <div
              style={{
                fontSize: "14px",
                marginBottom: "5px",
              }}
              className="text-center green"
            >
              ${price.toString()}
              .00
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const paneOpen = this.props.cartOpen;
    for (let item of this.props.cart) {
      if (item.growSquareCount) {
        break;
      }
    }
    return (
      <div ref={(ref) => (this.el = ref)}>
        {this.state.width < 1000 && this.renderMobileHeader()}
        {this.state.width >= 1000 && this.renderDesktopHeader()}
        {/* TODO: Toggle display: fixed when sliding pane clicked, to show the header of the pane in real time */}
        <SlidingPane
          isOpen={paneOpen}
          title="Shopping Cart"
          onRequestClose={(e) => {
            e.preventDefault();
            this.props.startPaneFalse();
          }}
          width={this.state.width > 600 ? "400px" : "300px"}
          style={{ backgroundColor: "#fafafa" }}
        >
          {isEmpty(this.props.inventory) ? (
            <div className="h-100 w-100 d-flex flex-row justify-content-center align-items-center">
              <Spinner animation="border" style={{ color: "#00807B" }} />
            </div>
          ) : (
            <>
              <div
                className={classNames({
                  "no-display": true,
                })}
              >
                <p
                  className="p text-center"
                  style={{
                    backgroundColor: "#00807B",
                    color: "#FFFFFF",
                    padding: "4% 24px",
                  }}
                >
                  You need{" "}
                  <span style={{ fontWeight: "700" }}>
                    {4 - this.state.cartQuant} more &nbsp;
                  </span>
                  {this.state.cartQuant === 3 ? "item" : "items"}
                  &nbsp;to meet the minimum order requirements
                </p>
              </div>
              <div
                style={{
                  display: "grid",
                  height: "100%",
                  gridTemplateRows: "3fr auto",
                }}
              >
                <div style={{ overflowY: "scroll" }}>
                  {this.props.cart.map((item, ind) =>
                    this.ShoppingCartCard(item, ind)
                  )}
                </div>

                <div className="cart__bottom">
                  {/* {this.props.cart.length > 0 && <hr />} */}
                  <Row style={{ marginBottom: "1rem" }}>
                    <Col xs={6}>
                      <span
                        style={{ fontFamily: "Poppins" }}
                        className="cart__bottom-title cart-subtotal"
                      >
                        Subtotal:
                      </span>
                    </Col>
                    <Col xs={6}>
                      <span
                        style={{ fontFamily: "Poppins" }}
                        className="cart__bottom-title plant-title--green d-flex flex-row justify-content-end cart-subtotal"
                      >
                        ${totalCostCalc(this.props.cart).toString()}
                        .00
                      </span>
                    </Col>
                  </Row>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "20px 0",
                    }}
                  >
                    <div className="cart-subtext cart-subtext-left">
                      Shipping, taxes and promotions are calculated at checkout.
                    </div>
                    <div className="cart-subtext">
                      {this.state.cartQuant} Items
                    </div>
                  </div>
                  <Row className="noMargin centeredRow">
                    <FunctionModal />
                  </Row>
                  <div className="cart-agree">
                    By checking out, I agree to the{" "}
                    <span
                      id="terms-of-use"
                      onClick={() => {
                        window.location.href = "/terms";
                      }}
                    >
                      Terms of Use
                    </span>{" "}
                    and acknowledge that I have read the{" "}
                    <span
                      id="privacy-policy"
                      onClick={() => {
                        window.location.href = "/privacy";
                      }}
                    >
                      Privacy Policy
                    </span>
                    .
                  </div>
                </div>
              </div>
            </>
          )}
        </SlidingPane>
      </div>
    );
  }
}
// button button--link for when u want button preset, but only some parts of it changed

const mapStateToProps = (state) => ({
  cart: state.cart.cart,
  cartOpen: state.cart.cartOpen,
  products: state.products,
  inventory: state.inventory,
});

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout()),
  startLogin: () => dispatch(startLogin()),
  startSetQuantity: (obj) => dispatch(startSetQuantity(obj)),
  startDeleteItem: (id) => dispatch(startDeleteItem(id)),
  startPaneFalse: () => dispatch(startPaneFalse()),
  startPaneTrue: () => dispatch(startPaneTrue()),
});

export const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
