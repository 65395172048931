import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { firebase } from "../firebase/firebase";
import { startPaneFalse } from "../actions/cart";
import { startLogout, startLogin } from "../actions/auth";
import PopupModal from "./PopupModal";
import "../styles/components/header.css";

function FunctionModal({ cart, functionPaneFalse }) {
  const [modalShow, setModalShow] = React.useState(false);
  const history = useHistory();

  function checkout() {
    const user = firebase.auth().currentUser;

    if (cart.length > 0) {
      if (user) {
        history.push("/startcheckout");
        functionPaneFalse();
      } else {
        setModalShow(true);
      }
    }
  }

  const isCheckoutAllowed = () => {
    return cart.length > 0; // allow checkout anyhow now
  };

  if (!isCheckoutAllowed()) {
    return (
      <div className="w-100">
        <button
          onClick={(e) => {
            checkout(e);
          }}
          className={classNames("button", "w-100", "cart-checkout")}
          style={{
            cursor: "not-allowed",
            color: "#FFFFFF",
            backgroundColor: "#CFCFCF",
            // borderColor: "#CFCFCF",
            border: "none",
          }}
          disabled
          size="lg"
          type="button"
        >
          Checkout
        </button>

        <PopupModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    );
  }

  return (
    <div className="w-100">
      <button
        onClick={(e) => {
          checkout(e);
        }}
        className={classNames("button", "w-100", "cart-checkout", {
          "button--disabled": !isCheckoutAllowed(),
          "checkout-btn-enabled": isCheckoutAllowed(),
        })}
        style={{ color: "#FFFFFF", backgroundColor: "#00807B", border: "none" }}
        size="lg"
        type="button"
      >
        Continue To Checkout
      </button>

      <PopupModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart.cart,
});

const mapDispatchToProps = (dispatch) => ({
  functionPaneFalse: () => dispatch(startPaneFalse()),
  startLogout: () => dispatch(startLogout()),
  startLogin: () => dispatch(startLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FunctionModal);
