import React from "react";
import styles from "./Textarea.module.scss";

export const Textarea = ({
  register,
  name,
  validation,
  errors,
  placeholder,
  rows = 3,
}) => {
  const hasError = errors && !!errors.errors[name];

  return (
    <div className={styles.textarea}>
      <textarea
        rows={rows}
        aria-describedby={name}
        placeholder={placeholder}
        {...register(name, validation)}
      />
      <span style={{ opacity: `${hasError ? 1 : 0}` }}>
        {errors.errors[name]?.message ?? "Please complete this required field."}
      </span>
    </div>
  );
};
