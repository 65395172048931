import React from "react";
import dompurify from "dompurify";

export const Redactor = ({ children, ...rest }) => {
  if (!children) return null;

  const sanitizer = dompurify.sanitize;

  return (
    <div dangerouslySetInnerHTML={{ __html: sanitizer(children) }} {...rest} />
  );
};
