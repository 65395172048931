import findProductData from "../services/productData";

export const addProducts = (obj) => ({
  type: "ADD_PRODUCTS",
  obj,
});

export const startFetchProducts = () => async (dispatch) => {
  const productData = await findProductData();
  dispatch(addProducts(productData));
};
