import React, { useState } from "react";
import { AccordionItem } from "./AccordionItem";
import { Redactor } from "../Redactor";
import styles from "./Accordion.module.scss";

export const Accordion = ({ accordionData }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleOpen = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className={styles.accordion}>
      {Object.keys(accordionData).map((key, index) => {
        return (
          <AccordionItem
            title={key}
            key={index}
            isActive={index === activeIndex}
            handleOpen={() => handleOpen(index)}
          >
            <Redactor>{accordionData[key]}</Redactor>
          </AccordionItem>
        );
      })}
    </div>
  );
};
