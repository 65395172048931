import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ReactGA from "react-ga";
import { AppRouter, history } from "./routers/AppRouter";
import configureStore from "./store/configureStore";
import { login, logout } from "./actions/auth";
import { startFetchProducts } from "./actions/products";
import { startFetchInventory } from "./actions/inventory";
import "bootstrap/dist/css/bootstrap.css";
import "normalize.css/normalize.css";
import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";
import "swiper/modules/navigation/navigation.scss";
import "./styles/styles.scss";

import { firebase } from "./firebase/firebase";

const store = configureStore();

ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING_ID);

const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById("app"));
    hasRendered = true;
  }
};

// dispatch for reducers changes
firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch(login(user.uid));
    renderApp();
  } else {
    // just logged out
    store.dispatch(logout());
    renderApp();
  }
});

store.subscribe(() => {
  const { cart, analysisModal, plantInfo, analysisInfo } = store.getState();
  localStorage.setItem("cart", JSON.stringify(cart));
  localStorage.setItem("analysisModal", analysisModal);
  localStorage.setItem("plantInfo", JSON.stringify(plantInfo));
  localStorage.setItem("analysisInfo", JSON.stringify(analysisInfo));
});

store.dispatch(startFetchProducts());
store.dispatch(startFetchInventory());

// Google analytics page tracking
history.listen((location) => {
  // update user's current page
  ReactGA.set({ page: location.pathname });
  // record the pageview for the given page
  ReactGA.pageview(location.pathname);
});
