import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { Header, Footer, CookieBanner } from "../components/Common";

export const PublicRoute = ({
  isAuthenticated,
  component: Component,
  ...rest
}) => {
  if (
    window.location.pathname === "/analysis1" ||
    window.location.pathname === "/analysis2" ||
    window.location.pathname === "/analysis3"
  ) {
    return (
      <Route
        {...rest}
        component={(props) => (
          <div>
            <Component {...props} />
          </div>
        )}
      />
    );
  }
  return (
    <Route
      {...rest}
      component={(props) => (
        <div>
          <Header {...props} />
          <Component {...props} />
          <Footer {...props} />
          <CookieBanner {...props} />
        </div>
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid, // if it exists, u r authed
});

export default connect(mapStateToProps)(PublicRoute);
