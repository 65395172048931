import React, { lazy } from "react";
import { Router, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { Loading } from "../components/Common";

const GrowTech = lazy(() => import("../components/home/GrowTech"));

const Analysis1 = lazy(() => import("../components/Analysis1"));
const Analysis2 = lazy(() => import("../components/Analysis2"));
const Analysis3 = lazy(() => import("../components/Analysis3"));
const Products = lazy(() => import("../components/home/Products/Products"));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy"));
const TermsOfService = lazy(() => import("../pages/TermsOfService"));
const Faq = lazy(() => import("../components/home/FaqPage"));
const CheckoutConfirm = lazy(() =>
  import("../components/CheckoutConfirmation")
);
const StartCheckout = lazy(() =>
  import("../components/checkout/StartCheckout")
);
const NewLandingSEM = lazy(() => import("../components/home/NewLandingSEM.js"));
const Landing = lazy(() => import("../components/home/Landing/Landing.js"));

const AboutUsPage = lazy(() => import("../components/home/AboutUsPage"));
const ProductDetailsPage = lazy(() =>
  import("../components/home/productDetailsComponents/ProductDetailsPage")
);
const GiftingPage = lazy(() => import("../components/home/GiftingPage"));
const ScrollToTop = lazy(() => import("../components/scrollToTop"));

// New Pages 21-Mar
const SquarePage = lazy(() => import("../pages/Square"));
const FeaturesPage = lazy(() => import("../pages/Features"));
const APIPage = lazy(() => import("../pages/API"));
const FaqPage = lazy(() => import("../pages/Faq"));

export const history = createBrowserHistory();

// setting history allows you to navigate within app.js
export const AppRouter = () => (
  <React.Suspense fallback={<Loading />}>
    <Router history={history}>
      <div>
        <ScrollToTop />
        <Switch>
          <PublicRoute path="/test" component={<Loading />} exact />
          <PublicRoute path="/" component={Landing} exact />
          <PublicRoute path="/landing" component={Landing} exact />
          <PublicRoute path="/landingsem" component={NewLandingSEM} exact />
          <PublicRoute path="/ourtech" component={GrowTech} exact />
          <PublicRoute path="/features" component={FeaturesPage} exact />
          <PublicRoute path="/analysis1" component={Analysis1} exact />
          <PublicRoute path="/analysis2" component={Analysis2} exact />
          <PublicRoute path="/analysis3" component={Analysis3} exact />
          <PublicRoute path="/products" component={Products} exact />
          <PrivateRoute path="/startcheckout" component={StartCheckout} exact />
          <PublicRoute path="/privacypolicy" component={PrivacyPolicy} exact />
          <PublicRoute path="/faq" component={Faq} exact />
          <PublicRoute path="/faq-new" component={FaqPage} exact />
          <PublicRoute path="/terms" component={TermsOfService} exact />
          <PublicRoute path="/about-us" component={AboutUsPage} exact />
          <PublicRoute path="/square" component={SquarePage} exact />
          <PublicRoute path="/api" component={APIPage} exact />
          <PublicRoute
            path="/productDetails"
            component={ProductDetailsPage}
            exact
          />
          <PublicRoute
            path="/productDetails/:plantCode"
            component={ProductDetailsPage}
          />
          <PublicRoute
            path="/checkoutconfirm"
            component={CheckoutConfirm}
            exact
          />
          <PublicRoute path="/gifting" component={GiftingPage} exact />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  </React.Suspense>
);

export default AppRouter;
