// For temporary use -- later want to pull directly from Firebase and initialize on site visit

export const findPlantProp = (strPlant) => {
  switch (strPlant) {
    case 'basil':
      return {
        id: 1,
        name: 'Basil',
        commonName: 'Sweet Organic',
        harvestStart: '60',
        harvestEnd: '60',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 66,
        temperatureMax: 85,
        phMin: 5.5,
        phMax: 6.5,
        water: 17.722,
        description:
          'A classic basil variety, Sweet Basil grows up to 18 inches tall and its leaves can be harvested and used at any point in their life span!',
        variants: [
          ['basil', '/images/GrownBasil.png', 'icon-img'],
          ['basil2', '/images/GrownBasil2.png', 'main-icon-img'],
          ['basil3', '/images/GrownBasil3.png', 'main-icon-img']
        ]
      };
    case 'basil2':
      return {
        id: 2,
        name: 'Basil',
        commonName: 'Dark Opal',
        harvestStart: '80',
        harvestEnd: '80',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 66,
        temperatureMax: 85,
        phMin: 5.5,
        phMax: 6.5,
        water: 17.722,
        description:
          'Spectacular as a garnish, in salads, or for adding color to basil vinegars. This all purple Italian strain stands out in your home garden.',
        variants: [
          ['basil', '/images/GrownBasil.png', 'icon-img'],
          ['basil2', '/images/GrownBasil2.png', 'main-icon-img'],
          ['basil3', '/images/GrownBasil3.png', 'main-icon-img']
        ]
      };
    case 'basil3':
      return {
        id: 33,
        name: 'Basil',
        commonName: 'Spicy Bush',
        harvestStart: '70',
        harvestEnd: '70',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 66,
        temperatureMax: 85,
        phMin: 5.5,
        phMax: 6.5,
        water: 17.722,
        description:
          'The Spicy Bush Basil is a very bushy basil variety that produces tiny, bright green leaves that have a big spicy, sweet punch!',
        variants: [
          ['basil', '/images/GrownBasil.png', 'icon-img'],
          ['basil2', '/images/GrownBasil2.png', 'main-icon-img'],
          ['basil3', '/images/GrownBasil3.png', 'main-icon-img']
        ]
      };
    case 'beets':
      return {
        id: 3,
        name: 'Beet',
        commonName: 'Boldor',
        harvestStart: '51',
        harvestEnd: '51',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 6,
        phMax: 7.5,
        water: 26.333,
        description:
          'The perfect golden beet, Boldor, has been praised for its uniform round-shaped roots, yellow stems and green tops. Their sweet flavor makes them a great addition to salads or roasted on their own.',
        variants: [
          ['beets', '/images/GrownBeets.png', 'icon-img'],
          ['beets2', '/images/GrownBeets2.png', 'main-icon-img'],
          ['beets3', '/images/GrownBeets3.png', 'icon-img']
        ]
      };
    case 'beets2':
      return {
        id: 4,
        name: 'Beet',
        commonName: 'Early Wonder Tall Top',
        harvestStart: '45',
        harvestEnd: '45',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 6,
        phMax: 7.5,
        water: 26.333,
        description:
          'Forming deep red 3-4 inch bulbs with matching red stems, Early Wonder Tall Top impresses gardeners all the time with its quick maturity and tasty greens.',
        variants: [
          ['beets', '/images/GrownBeets.png', 'icon-img'],
          ['beets2', '/images/GrownBeets2.png', 'main-icon-img'],
          ['beets3', '/images/GrownBeets3.png', 'icon-img']
        ]
      };
    case 'beets3':
      return {
        id: 5,
        name: 'Beet',
        commonName: 'Baby White Albino',
        harvestStart: '55',
        harvestEnd: '55',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 6,
        phMax: 7.5,
        water: 26.333,
        description:
          'These snow-white table beets are the sweetest of all table beets!',
        variants: [
          ['beets', '/images/GrownBeets.png', 'icon-img'],
          ['beets2', '/images/GrownBeets2.png', 'main-icon-img'],
          ['beets3', '/images/GrownBeets3.png', 'icon-img']
        ]
      };
    case 'bellpepper':
      return {
        id: 6,
        name: 'Peppers',
        commonName: 'Wonder Bell',
        harvestStart: '70',
        harvestEnd: '70',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 71,
        temperatureMax: 80,
        phMin: 5.5,
        phMax: 7,
        water: 15.928,
        description:
          'Named because the amount of fruit produced by each plant literally inspires wonders, the Wonder variety of bell pepper produces sweet 4-lobed fruits that ripen from green to a beautiful shade of red!',
        variants: [
          ['bellpepper', '/images/GrownPepper.png', 'icon-img'],
          ['bellpepper2', '/images/GrownPepper2.png', 'main-icon-img'],
          ['bellpepper3', '/images/GrownPepper3.png', 'icon-img']
        ]
      };
    case 'bellpepper2':
      return {
        id: 7,
        name: 'Peppers',
        commonName: 'Cozumel Hot',
        harvestStart: '53',
        harvestEnd: '53',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 71,
        temperatureMax: 80,
        phMin: 5.5,
        phMax: 7,
        water: 15.928,
        description:
          'Watch out, they are hot! 14-18 inch tall Cozumel Pepper plants produce yellow fruits which grow into 4 inch red peppers that have been drescibed as firey in taste.',
        variants: [
          ['bellpepper', '/images/GrownPepper.png', 'icon-img'],
          ['bellpepper2', '/images/GrownPepper2.png', 'main-icon-img'],
          ['bellpepper3', '/images/GrownPepper3.png', 'icon-img']
        ]
      };
    case 'bellpepper3':
      return {
        id: 8,
        name: 'Peppers',
        commonName: 'Pizza',
        harvestStart: '80',
        harvestEnd: '80',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 8,
        sunlightMax: 12,
        temperatureMin: 71,
        temperatureMax: 80,
        phMin: 5.5,
        phMax: 7,
        water: 15.928,
        description:
          'Pizza Peppers are perfect for anyone who likes just a little bit of spice. The 14-18 inch tall plant prodcues 3-4 inch cone shaped fruits that changes flavors as they transition from green to red.',
        variants: [
          ['bellpepper', '/images/GrownPepper.png', 'icon-img'],
          ['bellpepper2', '/images/GrownPepper2.png', 'main-icon-img'],
          ['bellpepper3', '/images/GrownPepper3.png', 'icon-img']
        ]
      };
    case 'broccoli':
      return {
        id: 9,
        name: 'Broccoli',
        commonName: 'Belstar',
        harvestStart: '100',
        harvestEnd: '100',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 6,
        phMax: 7,
        water: 17.938,
        description:
          'In blue-green, the 6 inch heads of this broccoli are not only well known for being sweet AND disease resistant, but their heads are held high off the plant, allowing for drama-free harvest.',
        variants: [
          ['broccoli', '/images/GrownBroccoli.png', 'icon-img'],
          ['broccoli2', '/images/GrownBroccoli2.png', 'main-icon-img'],
          ['broccoli3', '/images/GrownBroccoli3.png', 'icon-img']
        ]
      };
    case 'broccoli2':
      return {
        id: 10,
        name: 'Broccoli',
        commonName: 'Express',
        harvestStart: '105',
        harvestEnd: '105',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 6,
        phMax: 7,
        water: 17.938,
        description:
          'Beautiful and delicious, Express grows impressively uniform 6-7 inch heads and easily grows side shoots allowing for more broccoli after first harvest!',
        variants: [
          ['broccoli', '/images/GrownBroccoli.png', 'icon-img'],
          ['broccoli2', '/images/GrownBroccoli2.png', 'main-icon-img'],
          ['broccoli3', '/images/GrownBroccoli3.png', 'icon-img']
        ]
      };
    case 'broccoli3':
      return {
        id: 11,
        name: 'Broccoli',
        commonName: 'Umpqua',
        harvestStart: '80',
        harvestEnd: '80',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 6,
        phMax: 7,
        water: 17.938,
        description:
          "Umpqua is easily distinguishable by it's large & loosely packed florets, making it a total knockout in your garden! If that isn't enough, it also boasts a high yield over a ong harvest window.",
        variants: [
          ['broccoli', '/images/GrownBroccoli.png', 'icon-img'],
          ['broccoli2', '/images/GrownBroccoli2.png', 'main-icon-img'],
          ['broccoli3', '/images/GrownBroccoli3.png', 'icon-img']
        ]
      };
    case 'brusselsprouts':
      return {
        id: 12,
        name: 'Brussel Sprouts',
        commonName: 'Long Island',
        harvestStart: '100',
        harvestEnd: '100',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 55,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7.5,
        water: 35.426,
        description:
          'The Long Island is a compact Brussels sprout with heavy yields! This is a workhorse variety that produces very flavorful sprouts. This compact, 24″ tall plant yields 75-100 dark green, 1-2″ sprouts over a long period. It is great for small gardens.',
        variants: [
          ['brusselsprouts', '/images/GrownBrussel.png', 'icon-img'],
          ['brusselsprouts2', '/images/GrownBrussel2.png', 'main-icon-img'],
          ['brusselsprouts3', '/images/GrownBrussel3.png', 'icon-img']
        ]
      };
    case 'brusselsprouts2':
      return {
        id: 13,
        name: 'Brussel Sprouts',
        commonName: 'Rubine',
        harvestStart: '85',
        harvestEnd: '85',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 55,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7.5,
        water: 35.426,
        description:
          'An heirloom variety not often seen in the garden. Rubine offers up a bountiful harvest of gorgeous 1-1 1/2 inch purple-red sprouts that are full of wonderfully rich old-time flavor. A striking addition to any vegetable patch and a gourmet special for your table.',
        variants: [
          ['brusselsprouts', '/images/GrownBrussel.png', 'icon-img'],
          ['brusselsprouts2', '/images/GrownBrussel2.png', 'main-icon-img'],
          ['brusselsprouts3', '/images/GrownBrussel3.png', 'icon-img']
        ]
      };
    case 'brusselsprouts3':
      return {
        id: 14,
        name: 'Brussel Sprouts',
        commonName: 'Franklin',
        harvestStart: '80',
        harvestEnd: '80',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 55,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7.5,
        water: 35.426,
        description:
          'Franklin is one of the earliest maturing of all the Brussels sprouts. It has the added bonus of high quality, uniform, firm sprouts. The plants are quite tall and have less woody stalks so whole stem harvests are possible.',
        variants: [
          ['brusselsprouts', '/images/GrownBrussel.png', 'icon-img'],
          ['brusselsprouts2', '/images/GrownBrussel2.png', 'main-icon-img'],
          ['brusselsprouts3', '/images/GrownBrussel3.png', 'icon-img']
        ]
      };
    case 'carrots':
      return {
        id: 15,
        name: 'Carrot',
        commonName: 'Rodelika',
        harvestStart: '72',
        harvestEnd: '72',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 5.5,
        phMax: 7,
        water: 18.56,
        description:
          'Well known for its sweet flavor, these 6-8 inch long carrots are perfect for juicing or raw eating!',
        variants: [
          ['carrots', '/images/GrownCarrot.png', 'icon-img'],
          ['carrots2', '/images/GrownCarrot2.png', 'main-icon-img'],
          ['carrots3', '/images/GrownCarrot3.png', 'icon-img']
        ]
      };
    case 'carrots2':
      return {
        id: 16,
        name: 'Carrot',
        commonName: 'Caracas Mini',
        harvestStart: '57',
        harvestEnd: '57',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 5.5,
        phMax: 7,
        water: 18.56,
        description:
          'At only 4 inches long, these baby carrots are not only an adorable addition to your garden, but they mature quickly and their broad tops allow for easy harvest.',
        variants: [
          ['carrots', '/images/GrownCarrot.png', 'icon-img'],
          ['carrots2', '/images/GrownCarrot2.png', 'main-icon-img'],
          ['carrots3', '/images/GrownCarrot3.png', 'icon-img']
        ]
      };
    case 'carrots3':
      return {
        id: 17,
        name: 'Carrot',
        commonName: 'Giants of Colmar',
        harvestStart: '75',
        harvestEnd: '75',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 76,
        temperatureMax: 85,
        phMin: 5.5,
        phMax: 7,
        water: 18.56,
        description:
          'Take their name seriously! The red-orange Giants of Colmar carrots grow up to 1 foot long. And we are so happy they do because we cant enough of their sweet flavor!',
        variants: [
          ['carrots', '/images/GrownCarrot.png', 'icon-img'],
          ['carrots2', '/images/GrownCarrot2.png', 'main-icon-img'],
          ['carrots3', '/images/GrownCarrot3.png', 'icon-img']
        ]
      };
    case 'cilantro':
      return {
        id: 18,
        name: 'Cilantro',
        commonName: 'Slow Bolt',
        harvestStart: '50',
        harvestEnd: '50',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 70,
        temperatureMax: 80,
        phMin: 6.5,
        phMax: 7.5,
        water: 19.881,
        description:
          "This Cilantro isn't called Slow Bolt for nothing! This variety will provide you with Cilantro leaves longer than any other variety!",
        variants: [
          ['cilantro', '/images/GrownCilantro.png', 'icon-img'],
          ['cilantro2', '/images/GrownCilantro2.png', 'main-icon-img']
        ]
      };
    case 'cilantro2':
      return {
        id: 19,
        name: 'Cilantro',
        commonName: 'Common',
        harvestStart: '50',
        harvestEnd: '50',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 70,
        temperatureMax: 80,
        phMin: 6.5,
        phMax: 7.5,
        water: 19.881,
        description:
          "When asked to describe the flavor of this Cilantro folks went with 'pure cilantro.' Who could ask for anyting more?",
        variants: [
          ['cilantro', '/images/GrownCilantro.png', 'icon-img'],
          ['cilantro2', '/images/GrownCilantro2.png', 'main-icon-img']
        ]
      };
    case 'cucumber':
      return {
        id: 20,
        name: 'Cucumbers',
        commonName: 'Lemon',
        harvestStart: '65',
        harvestEnd: '65',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 81,
        temperatureMax: 90,
        phMin: 5.5,
        phMax: 7.5,
        water: 21.135,
        description:
          'This unusual shaped bright yellow heirloom have a clean, crisp taste and are never bitter.',
        variants: [
          ['cucumber', '/images/GrownCucumber.png', 'icon-img'],
          ['cucumber2', '/images/GrownCucumber2.png', 'main-icon-img'],
          ['cucumber3', '/images/GrownCucumber3.png', 'icon-img']
        ]
      };
    case 'cucumber2':
      return {
        id: 21,
        name: 'Cucumbers',
        commonName: 'Mexican Sour Gherkin',
        harvestStart: '65',
        harvestEnd: '65',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 81,
        temperatureMax: 90,
        phMin: 5.5,
        phMax: 7.5,
        water: 21.135,
        description:
          'These tiny, sweet treats have sweet cucumber flavor combined with a tangy sourness. They yield a bounty of tiny cukes for salads, snacks and pickling.',
        variants: [
          ['cucumber', '/images/GrownCucumber.png', 'icon-img'],
          ['cucumber2', '/images/GrownCucumber2.png', 'main-icon-img'],
          ['cucumber3', '/images/GrownCucumber3.png', 'icon-img']
        ]
      };
    case 'cucumber3':
      return {
        id: 22,
        name: 'Cucumbers',
        commonName: 'Mini Munch',
        harvestStart: '55',
        harvestEnd: '55',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 81,
        temperatureMax: 90,
        phMin: 5.5,
        phMax: 7.5,
        water: 21.135,
        description:
          'At just 3 or 4 inches in dark green, these quick maturing cucumber sare both adorable and reliable for a faster harvest than other varieties.',
        variants: [
          ['cucumber', '/images/GrownCucumber.png', 'icon-img'],
          ['cucumber2', '/images/GrownCucumber2.png', 'main-icon-img'],
          ['cucumber3', '/images/GrownCucumber3.png', 'icon-img']
        ]
      };
    case 'greenonion':
      return {
        id: 23,
        name: 'Scallions',
        commonName: 'Tokyo Long Bunching',
        harvestStart: '75',
        harvestEnd: '75',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 55,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7,
        water: 12.109,
        description:
          'The Tokyo Long White Bunching Onion – or Scallion – is an extremely tasty Japanese variety. The stalk bottoms are pure white, while the pungent, flavorful tops are medium green.',
        variants: [
          ['greenonion', '/images/GrownOnion.png', 'icon-img'],
          ['greenonion2', '/images/GrownOnion2.png', 'main-icon-img']
        ]
      };
    case 'greenonion2':
      return {
        id: 24,
        name: 'Scallions',
        commonName: 'Nebuka Evergreen Bunching',
        harvestStart: '65',
        harvestEnd: '65',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 55,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7,
        water: 12.109,
        description:
          'This variety is a splitting type onion with long, slender white stalks that grow in tight clusters. This onion is an essential to Japanese and Chinese cooking, fresh or cooked, or used as a garnish. Nebuka Evergreen Bunching is hardy and cold resistant.',
        variants: [
          ['greenonion', '/images/GrownOnion.png', 'icon-img'],
          ['greenonion2', '/images/GrownOnion2.png', 'main-icon-img']
        ]
      };
    case 'lettuce':
      return {
        id: 25,
        name: 'Lettuce',
        commonName: 'Two Star Looseleaf',
        harvestStart: '45',
        harvestEnd: '45',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 4,
        sunlightMax: 6,
        temperatureMin: 66,
        temperatureMax: 70,
        phMin: 6,
        phMax: 7,
        water: 19.881,
        description:
          'Fun and frilly, these large plants stand around 6-7 inches with leaves that are equally as wide. Their chartreuse color will add a pop to your garden!',
        variants: [
          ['lettuce', '/images/GrownLettuce.png', 'icon-img'],
          ['lettuce2', '/images/GrownLettuce2.png', 'main-icon-img'],
          ['lettuce3', '/images/GrownLettuce3.png', 'icon-img']
        ]
      };
    case 'lettuce2':
      return {
        id: 26,
        name: 'Lettuce',
        commonName: 'Valmaine Romaine',
        harvestStart: '56',
        harvestEnd: '56',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 4,
        sunlightMax: 6,
        temperatureMin: 66,
        temperatureMax: 70,
        phMin: 6,
        phMax: 7,
        water: 19.881,
        description:
          'Valmaine Romaine, a classic choice for Caesar salad, can be grown up to 1 foot tall and still remain crisp.',
        variants: [
          ['lettuce', '/images/GrownLettuce.png', 'icon-img'],
          ['lettuce2', '/images/GrownLettuce2.png', 'main-icon-img'],
          ['lettuce3', '/images/GrownLettuce3.png', 'icon-img']
        ]
      };
    case 'lettuce3':
      return {
        id: 27,
        name: 'Lettuce',
        commonName: 'Buckley Looseleaf',
        harvestStart: '55',
        harvestEnd: '55',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 4,
        sunlightMax: 6,
        temperatureMin: 66,
        temperatureMax: 70,
        phMin: 6,
        phMax: 7,
        water: 19.881,
        description:
          'This red looseleaf variety of lettuce grows in rosettes of uniformly sized leaves. Easy to harvest by head or just pluck a few leaves here and there.',
        variants: [
          ['lettuce', '/images/GrownLettuce.png', 'icon-img'],
          ['lettuce2', '/images/GrownLettuce2.png', 'main-icon-img'],
          ['lettuce3', '/images/GrownLettuce3.png', 'icon-img']
        ]
      };
    case 'peas':
      return {
        id: 28,
        name: 'Peas',
        commonName: 'Sugar Ann Snap',
        harvestStart: '55',
        harvestEnd: '55',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 66,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7.5,
        water: 18.56,
        description:
          'Non-climbing and easy to grow, Sugar Ann Snap produces 2.5 inch long pods of sweet peas. Perfect to grow with your sweet pea!',
        variants: [
          ['peas', '/images/GrownPea.png', 'icon-img'],
          ['peas2', '/images/GrownPea2.png', 'main-icon-img'],
          ['peas3', '/images/GrownPea3.png', 'main-icon-img']
        ]
      };
    case 'peas2':
      return {
        id: 29,
        name: 'Peas',
        commonName: 'Sugar Daddy Snap',
        harvestStart: '65',
        harvestEnd: '65',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 66,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7.5,
        water: 18.56,
        description:
          'With perfect 3 inch long pods and self supporting vines, these 24 inch Sugar Daddy Snap Pea plants are sure to treat you right.',
        variants: [
          ['peas', '/images/GrownPea.png', 'icon-img'],
          ['peas2', '/images/GrownPea2.png', 'main-icon-img'],
          ['peas3', '/images/GrownPea3.png', 'main-icon-img']
        ]
      };
    case 'peas3':
      return {
        id: 34,
        name: 'Peas',
        commonName: 'Serge Shelling',
        harvestStart: '68',
        harvestEnd: '68',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 6,
        sunlightMax: 8,
        temperatureMin: 66,
        temperatureMax: 75,
        phMin: 6,
        phMax: 7.5,
        water: 18.56,
        description:
          "You can't grow wrong with Serge's self-supporting, semi leafeless vines that protect sweet 3.5 inch peapods!",
        variants: [
          ['peas', '/images/GrownPea.png', 'icon-img'],
          ['peas2', '/images/GrownPea2.png', 'main-icon-img'],
          ['peas3', '/images/GrownPea3.png', 'main-icon-img']
        ]
      };
    case 'spinach':
      return {
        id: 30,
        name: 'Spinach',
        commonName: 'Spiros',
        harvestStart: '56',
        harvestEnd: '56',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 4,
        sunlightMax: 6,
        temperatureMin: 56,
        temperatureMax: 65,
        phMin: 6,
        phMax: 7.5,
        water: 8.222,
        description:
          "4 inches tall and able to easily spread 14 inches wide, Spiros Spinach is known for it's great germination & resistance to bolting.",
        variants: [
          ['spinach', '/images/GrownSpinach.png', 'icon-img'],
          ['spinach2', '/images/GrownSpinach2.png', 'main-icon-img'],
          ['spinach3', '/images/GrownSpinach3.png', 'icon-img']
        ]
      };
    case 'spinach2':
      return {
        id: 31,
        name: 'Spinach',
        commonName: 'Responder',
        harvestStart: '42',
        harvestEnd: '42',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 4,
        sunlightMax: 6,
        temperatureMin: 56,
        temperatureMax: 65,
        phMin: 6,
        phMax: 7.5,
        water: 8.222,
        description:
          'There is no question that this quick maturing, disease resistant spinach will impress you. The 6 inch tall plant produces dark green, arrow shaped leaves that are full of health benefits.',
        variants: [
          ['spinach', '/images/GrownSpinach.png', 'icon-img'],
          ['spinach2', '/images/GrownSpinach2.png', 'main-icon-img'],
          ['spinach3', '/images/GrownSpinach3.png', 'icon-img']
        ]
      };
    case 'spinach3':
      return {
        id: 32,
        name: 'Spinach',
        commonName: 'Bloomsdale Savoy',
        harvestStart: '50',
        harvestEnd: '50',
        weightStart: '1.5',
        weightEnd: '2.0',
        sunlightMin: 4,
        sunlightMax: 6,
        temperatureMin: 56,
        temperatureMax: 65,
        phMin: 6,
        phMax: 7.5,
        water: 8.222,
        description:
          'Succulent and crupled leaves are standard for Bloomsdale Savoy. It is well known for its garden-fresh flavor.',
        variants: [
          ['spinach', '/images/GrownSpinach.png', 'icon-img'],
          ['spinach2', '/images/GrownSpinach2.png', 'main-icon-img'],
          ['spinach3', '/images/GrownSpinach3.png', 'icon-img']
        ]
      };
    default:
      return {
        name: 'None found',
        commonName: 'None found'
      };
  }
};

export const findStrFromId = (id) => {
  switch (id) {
    case 1:
      return 'basil';
    case 2:
      return 'basil2';
    case 3:
      return 'beets';
    case 4:
      return 'beets2';
    case 5:
      return 'beets3';
    case 6:
      return 'bellpepper';
    case 7:
      return 'bellpepper2';
    case 8:
      return 'bellpepper3';
    case 9:
      return 'broccoli';
    case 10:
      return 'broccoli2';
    case 11:
      return 'broccoli3';
    case 12:
      return 'brusselsprouts';
    case 13:
      return 'brusselsprouts2';
    case 14:
      return 'brusselsprouts3';
    case 15:
      return 'carrots';
    case 16:
      return 'carrots2';
    case 17:
      return 'carrots3';
    case 18:
      return 'cilantro';
    case 19:
      return 'cilantro2';
    case 20:
      return 'cucumber';
    case 21:
      return 'cucumber2';
    case 22:
      return 'cucumber3';
    case 23:
      return 'greenonion';
    case 24:
      return 'greenonion2';
    case 25:
      return 'lettuce';
    case 26:
      return 'lettuce2';
    case 27:
      return 'lettuce3';
    case 28:
      return 'peas';
    case 29:
      return 'peas2';
    case 30:
      return 'spinach';
    case 31:
      return 'spinach2';
    case 32:
      return 'spinach3';
    case 33:
      return 'basil3';
    case 34:
      return 'peas3';
    default:
      return 0;
  }
};

export const findDataFromId = (id) => {
  switch (id) {
    case 1:
      return 'BSL01';
    case 2:
      return 'BSL02';
    case 3:
      return 'BET01';
    case 4:
      return 'BET02';
    case 5:
      return 'BET03';
    case 6:
      return 'PEP01';
    case 7:
      return 'PEP02';
    case 8:
      return 'PEP03';
    case 9:
      return 'BRC01';
    case 10:
      return 'BRC02';
    case 11:
      return 'BRC03';
    case 12:
      return 'BSP01';
    case 13:
      return 'BSP02';
    case 14:
      return 'BSP03';
    case 15:
      return 'CAR01';
    case 16:
      return 'CAR02';
    case 17:
      return 'CAR03';
    case 18:
      return 'CIL01';
    case 19:
      return 'CIL02';
    case 20:
      return 'CUC01';
    case 21:
      return 'CUC02';
    case 22:
      return 'CUC03';
    case 23:
      return 'ONI01';
    case 24:
      return 'ONI02';
    case 25:
      return 'LET01';
    case 26:
      return 'LET02';
    case 27:
      return 'LET03';
    case 28:
      return 'PEA01';
    case 29:
      return 'PEA02';
    case 30:
      return 'SPN01';
    case 31:
      return 'SPN02';
    case 32:
      return 'SPN03';
    case 33:
      return 'BSL03';
    case 34:
      return 'PEA03';
    default:
      return 0;
  }
};

export const totalCostCalc = (array) => {
  // give it an array, it gives you back the cost of the cart since each growsquare is $25
  let total = 0;
  array.forEach((item) => {
    let growSquareCount = item.growSquareCount;
    if(growSquareCount){
      total += item.quantity * growSquareCount * 25;
    }else{
      total += item.quantity * 25;
    }
  });
  return total;
};

export const realQuant = (array) => {
  // since a cart is in the form of [{id:1, quantity:5}, {id:4, quantity: 7}],
  // you can't use just .length because it'll return a 2. The real quantity is 12
  let total = 0;
  array.forEach((item) => {
    total += item.quantity;
  });
  return total;
};
