import React from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { startPaneFalse } from "../actions/cart";
import { startLogout, startLogin } from "../actions/auth";

function PopupModal(props) {
  const history = useHistory();

  function checkout() {
    props.startLogin().then(() => {
      props.functionPaneFalse();
      history.push("/startcheckout");
    });
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div className="signin-modal">
          <h4 class="h4">Join GrowSquares</h4>
          <p class="p">
            <span>
              Because we send a confirmation email afterwards, it is required
              for you to sign into your Google account to continue with the
              checkout process.
            </span>
          </p>

          <img
            alt=""
            style={{ width: "185px", cursor: "pointer", margin: "2rem 0" }}
            onClick={(e) => checkout(e)}
            src="/images/google_signin.png"
          />

          <p class="p">
            <span>
              To make GrowSquares work, we log user data and share it with
              service providers. Sign in to accept GrowSquare&apos;s &nbsp;
              <Link to="/privacypolicy" style={{ color: "#00807B" }}>
                Privacy Policy.
              </Link>
            </span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  functionPaneFalse: () => dispatch(startPaneFalse()),
  startLogout: () => dispatch(startLogout()),
  startLogin: () => dispatch(startLogin()),
});

export default connect(null, mapDispatchToProps)(PopupModal);
