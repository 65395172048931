import { getInventoryForVariant } from "../components/checkout/shopify_helpers";

const variantIds = [
  39431635140814, 39431636222158, 39431636320462, 39431582351566,
  39431635108046, 39431636353230, 39431636385998, 39431636418766,
  39431636451534, 39431636746446, 39431636811982, 39431637631182,
  39431637795022, 39431637926094, 39431638352078, 39431637434574,
  39431638384846, 39431638417614,
];
export default async function findProductInventory() {
  let productInventory = {};
  for (let currVariantId of variantIds) {
    const currentInventoryCount = await getInventoryForVariant(
      currVariantId + ""
    );
    productInventory[currVariantId] = currentInventoryCount;
  }
  return productInventory;
}
