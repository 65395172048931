import React, { useCallback } from "react";
import { useCookies } from "react-cookie";
import styles from "./CookieBanner.module.scss";

export const CookieBanner = () => {
  const COOKIE_NAME = "growSquareCookie";
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);

  const onClickClose = useCallback(() => {
    setCookie(COOKIE_NAME, true, { path: "/" });
  }, [setCookie]);

  if (cookies[COOKIE_NAME]) return <></>;

  return (
    <div className={styles.cookieBanner}>
      <div className={styles.cookieBanner__content}>
        <h5 className={styles.cookieBanner__title}>
          Thank you for accepting cookies
        </h5>
        <p>
          We and our partners use cookies to improve and personalize your
          experience on the site. To learn more about the cookies being used on
          our site checkout our{" "}
          <a
            href="https://storage.googleapis.com/growsquares_website_assets/footer/Website%20User%20Agreement.pdf"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
          .
        </p>
      </div>

      <div>
        <button className={styles.cookieBanner__button} onClick={onClickClose}>
          Accept
        </button>
      </div>
    </div>
  );
};
