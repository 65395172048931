// Takes an Admin REST API Product variant id and returns the
// storefront variant id
const getStorefrontVariantID = async (restProductID) => {
  let proxyurl;
  if (process.env.NODE_ENV === "production") {
    proxyurl = "https://growsquares-proxy.herokuapp.com/";
  } else {
    proxyurl = "https://infinite-hamlet-05570.herokuapp.com/";
  }
  const shopifyUrl =
    "https://growsquares-cart.myshopify.com/admin/api/2021-04/graphql.json";
  const storeFrontUncodedID = `gid://shopify/ProductVariant/${restProductID}`;
  const response = await fetch(proxyurl + shopifyUrl, {
    method: "POST",
    headers: {
      "X-Shopify-Access-Token": "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",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `
      query ProductVariant($restProductID: ID!) {
        productVariant (id: $restProductID) {
          id
          title
          storefrontId
        }
      }
      `,
      variables: {
        restProductID: storeFrontUncodedID,
      },
    }),
  });
  const res = await response.json();
  return res.data.productVariant.storefrontId;
};

const constructShopifyInput = async (email, cartProducts) => {
  let items = [];
  for (let i = 0; i < cartProducts.length; i++) {
    let product = cartProducts[i];
    let temp = {};
    const test = await getStorefrontVariantID(product["shopify_variant_id"]);
    temp["variantId"] = test;
    temp["quantity"] = product["quantity"];
    items.push(temp);
  }
  return {
    email: email,
    lineItems: items,
  };
};

export const conductShopifyCheckout = async (email, cartProducts) => {
  const burner = await constructShopifyInput(email, cartProducts);
  const shopifyInput = burner;
  let proxyurl;
  if (process.env.NODE_ENV === "production") {
    proxyurl = "https://growsquares-proxy.herokuapp.com/";
  } else {
    proxyurl = "https://infinite-hamlet-05570.herokuapp.com/";
  }
  const shopifyUrl =
    "https://growsquares-cart.myshopify.com/api/2021-04/graphql.json";
  fetch(proxyurl + shopifyUrl, {
    method: "POST",
    headers: {
      "X-Shopify-Storefront-Access-Token": "677568eb694ab7c4e961be7766519d8b",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: `
      mutation checkoutCreate($input: CheckoutCreateInput!) {
        checkoutCreate(input: $input) {
          checkout {
            id
            webUrl
            lineItems(first: 5) {
              edges {
                node {
                  id
                  quantity
                  title
                }
              }
            }
          }
          checkoutUserErrors {
            code
            field
            message
          }
        }
      }
      `,
      variables: {
        input: shopifyInput,
      },
    }),
  })
    .then((res) => res.json())
    .then((result) => {
      const shopifyCheckoutUrl = result.data.checkoutCreate.checkout.webUrl;
      // consle.log(shopifyCheckoutUrl);
      // return shopifyCheckoutUrl;
      window.location.href = shopifyCheckoutUrl;
    });
};

// Gets the inventory details from Shopify and further down
// the component tree, it is checked whether there is enough
// inventory to add the item to the cart
// Does this buy querying the Shopify Admin Rest API
export const getInventoryForVariant = async (variantId) => {
  let proxyurl;
  if (process.env.NODE_ENV === "production") {
    proxyurl = "https://growsquares-proxy.herokuapp.com/";
  } else {
    proxyurl = "https://infinite-hamlet-05570.herokuapp.com/";
  }
  const shopifyUrl = `https://8bc35a64ab6954dbdaf994301d164f60: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@growsquares-cart.myshopify.com/admin/api/2021-07/variants/${variantId}.json`;
  const response = await fetch(proxyurl + shopifyUrl, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "X-Shopify-Access-Token": "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",
    },
  });
  const res = await response.json();
  try {
    const availableQuant = res.variant.inventory_quantity;
    return availableQuant;
  } catch (error) {
    console.log(error);
    console.log(res);
  }
};
