import React from "react";
import { Link } from "react-router-dom";
import database from "../../../firebase/firebase";

import "../../../styles/components/_analysis.css";
import "../../../styles/components/_landing.css";
import "../../../styles/components/_new-footer.css";

export class Footer extends React.Component {
  state = { email: "" };

  h3Style = {
    fontSize: "medium",
    textTransform: "uppercase",
    color: "#fafafa",
    fontWeight: "500",
  };

  h3StyleMobile = {
    fontSize: "medium",
    textTransform: "uppercase",
    color: "#fafafa",
    fontWeight: "500",
  };

  buttonStyle = {
    fontSize: "0.9em",
    color: "#EBEAEA",
    width: "fit-content",
    padding: "0",
    border: "0",
    margin: "5px 5px 5px 0",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
  };

  buttonStyleMobile = {
    color: "#EBEAEA",
    width: "fit-content",
    padding: "0",
    border: "0",
    margin: "3px 3px 3px 0",
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    fontSize: "12px",
    fontWeight: "300",
  };

  componentDidMount = () => {
    var imgDefer = document.getElementsByTagName("img");
    for (var i = 0; i < imgDefer.length; i++) {
      if (imgDefer[i].getAttribute("data-src")) {
        imgDefer[i].setAttribute("src", imgDefer[i].getAttribute("data-src"));
      }
    }
    let email_entry = document.getElementById("email-entry");
    email_entry.addEventListener("keydown", (e) => {
      if (e.code === "Enter") {
        this.submitSubscribe();
      }
    });
  };

  handleChange = (event) => {
    event.preventDefault();

    this.setState({ email: event.target.value });
  };

  submitSubscribe = () => {
    if (this.state.email.length === 0) {
      // eslint-disable-next-line no-console
      console.error("Enter email.");
    } else {
      const myRef = database.ref("subscriptions");
      const { key } = myRef.push();
      myRef.update({ [key]: this.state.email }).then(() => {
        this.setState({ email: "" });
      });
    }
    let email_entry = document.getElementById("email-entry");
    email_entry.placeholder = "Thanks for signing up!";
  };

  renderUpperFooter() {
    if (window.innerWidth > 750) {
      return (
        <div className="upper-footer">
          <div
            className="email-Subscription"
            style={{ alignSelf: "flex-start" }}
          >
            <h3 style={this.h3Style}> SIGN UP FOR THE LATEST</h3>
            <p
              className="button-type-2"
              style={{
                fontSize: "medium",
                fontWeight: "400",
                color: "#ffffff",
                padding: "0",
                border: "0",
                margin: "7px 5px 10px 0",
                lineHeight: "1.58",
              }}
            >
              How else would we tell you all about monthly <br /> drops,
              giveaways, and plant care?
            </p>
            <div>
              <input
                onChange={this.handleChange}
                className="p"
                style={{ width: "25vw", color: "#9e9e9e" }}
                type="text"
                name=""
                id="email-entry"
                value={this.state.email}
                placeholder="Your email address"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.submitSubscribe();
                }}
                className="p"
                style={{
                  color: "#9e9e9e",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #9e9e9e",
                }}
              >
                →
              </button>
            </div>
          </div>
          <div className="links-section">
            <div className="links">
              <h3 style={this.h3Style}>Product</h3>
              <Link
                className="button-type-2"
                style={this.buttonStyle}
                to="/products"
              >
                Inventory
              </Link>
              <Link
                className="button-type-2"
                style={this.buttonStyle}
                to="/gifting"
              >
                Gifting
              </Link>
            </div>
            <div className="links">
              <h3 style={this.h3Style}>Our Team</h3>
              <a
                className="button-type-2"
                style={this.buttonStyle}
                href="https://apply.workable.com/growsquares/"
                target="_blank"
                rel="noreferrer"
              >
                Careers
              </a>
              <Link
                className="button-type-2"
                style={this.buttonStyle}
                to="/about-us"
              >
                About Us
              </Link>
            </div>
            <div className="links">
              <h3 style={this.h3Style}>Support</h3>
              <a
                className="button-type-2"
                style={this.buttonStyle}
                href="mailto:info@growsquares.com"
              >
                Contact Us
              </a>
              {/* <Link className="button-type-2" style={this.buttonStyle} to="/#">
                Press Inquiry
              </Link> */}
              <Link
                className="button-type-2"
                style={this.buttonStyle}
                to="/faq"
              >
                FAQs
              </Link>
            </div>
          </div>
        </div>
      );
    } else {
      // mobile
      return (
        <div className="upper-footer">
          <div
            className="email-Subscription"
            style={{ alignSelf: "flex-start" }}
          >
            <h3 style={this.h3StyleMobile}> SIGN UP FOR THE LATEST</h3>
            <p
              className="p"
              style={{
                fontSize: "1.1rem",
                color: "#cfcfcf",
                margin: "15px 5px 10px 0",
              }}
            >
              How else would we tell you all about monthly drops, giveaways, and
              plant care?
            </p>
            <div className="d-flex flex-row justify-content-start">
              <input
                onChange={this.handleChange}
                className="p"
                id="email-entry"
                style={{ width: "70%", color: "#B8B8B8", borderRadius: "0" }}
                type="text"
                name=""
                value={this.state.email}
                placeholder="Your email address"
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  this.submitSubscribe();
                }}
                className="p"
                style={{
                  color: "#d8fcee",
                  backgroundColor: "transparent",
                  borderBottom: "2px solid #d8fcee",
                }}
              >
                →
              </button>
            </div>
          </div>
          <div className="links-section">
            <div className="links">
              <h3 style={this.h3StyleMobile}>Product</h3>
              <Link
                className="button-type-2"
                style={this.buttonStyleMobile}
                to="/products"
              >
                Inventory
              </Link>
              <Link
                className="button-type-2"
                style={this.buttonStyleMobile}
                to="/gifting"
              >
                Gifting
              </Link>
            </div>
            <div className="links">
              <h3 style={this.h3StyleMobile}>Our Team</h3>
              <a
                className="button-type-2"
                style={this.buttonStyleMobile}
                href="https://apply.workable.com/growsquares/"
                target="_blank"
                rel="noreferrer"
              >
                Careers
              </a>
              <Link
                className="button-type-2"
                style={this.buttonStyleMobile}
                to="/about-us"
              >
                About Us
              </Link>
            </div>
            <div className="links">
              <h3 style={this.h3StyleMobile}>Support</h3>
              <a
                className="button-type-2"
                style={this.buttonStyleMobile}
                href="mailto:info@growsquares.com"
              >
                Contact Us
              </a>
              {/* <Link
                className="button-type-2"
                style={this.buttonStyleMobile}
                to="/#"
              >
                Press Inquiry
              </Link> */}
              <Link
                className="button-type-2"
                style={this.buttonStyleMobile}
                to="/faq"
              >
                FAQs
              </Link>
            </div>
          </div>
        </div>
      );
    }
  }

  renderLowerFooter() {
    if (window.innerWidth > 750) {
      return (
        <div className="lower-footer">
          <div className="rights-privacy" style={{ alignSelf: "center" }}>
            <img
              // src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src=""
              alt=""
            />
            <p style={{ margin: "0", color: "#00807B" }}>
              © Growsquares, Inc. 2022. All Rights Reserved
            </p>
            <a
              className="button-type-2 p"
              style={{
                fontSize: "0.9em",
                color: "#00807B",
                width: "fit-content",
                padding: "0",
                border: "0",
                margin: "0 0 0 20px",
              }}
              href="/privacypolicy"
            >
              Privacy Policy
            </a>
            <a
              className="button-type-2 p"
              style={{
                fontSize: "0.9em",
                color: "#00807B",
                width: "fit-content",
                padding: "0",
                border: "0",
                margin: "0 0 0 20px",
              }}
              href="/terms"
            >
              Terms Of Service
            </a>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ margin: "0", color: "#00807B" }}>
              370 Jay St. 7th Floor, Brooklyn, NY 11201
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="lower-footer">
          <div className="rights-privacy" style={{ alignSelf: "center" }}>
            <img
              // src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
              data-src=""
              alt=""
            />
            <p
              style={{
                fontSize: "1.5em",
                margin: "0 0 5% 0",
                color: "#cfcfcf",
              }}
            >
              © Growsquares, Inc. 2022. All Rights Reserved
            </p>
          </div>
          <div>
            <a
              className="button-type-2 p"
              style={{
                fontSize: "1.2em",
                color: "#EBEAEA",
                width: "fit-content",
                padding: "0",
                border: "0",
                margin: "0 0 0 20px",
              }}
              href="/privacypolicy"
            >
              Privacy Policy
            </a>
            <a
              className="button-type-2 p"
              style={{
                fontSize: "1.2em",
                color: "#EBEAEA",
                width: "fit-content",
                padding: "0",
                border: "0",
                margin: "0 0 0 20px",
              }}
              href="https://storage.googleapis.com/growsquares_website_assets/footer/Terms%20and%20Conditions_GrowSquares.doc.pdf"
            >
              Terms Of Service
            </a>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="new-footer">
        {this.renderUpperFooter()}

        <div className="mid-footer">
          <div
            style={{
              height: "fit-content",
            }}
          >
            <a href="https://apps.apple.com/us/app/growsquares/id1419649063">
              <img
                style={{
                  width: "50%",
                  height: "auto",
                  marginTop: "12%",
                  marginBottom: "12%",
                }}
                src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                data-src="https://storage.googleapis.com/growsquares_website_assets/footer/apple.png"
                alt=""
              />
            </a>
          </div>
          <div className="socials" style={{ textAlign: "right" }}>
            <h3 style={this.h3Style}>Follow us</h3>
            <div className="socials__icons">
              <a
                href="https://www.facebook.com/growsquaresapp/"
                target="__blank"
              >
                <img
                  src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  data-src="https://storage.googleapis.com/growsquares_website_assets/footer/facebook.svg"
                  alt="facebook"
                />
              </a>
              <a
                href="https://www.instagram.com/growsquaresgarden/"
                target="__blank"
              >
                <img
                  src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  data-src="https://storage.googleapis.com/growsquares_website_assets/footer/instagram.svg"
                  alt="instagram"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/growsquares/"
                target="__blank"
              >
                <img
                  src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  data-src="https://storage.googleapis.com/growsquares_website_assets/footer/linkedin.svg"
                  alt="linkedin"
                />
              </a>
            </div>
          </div>
        </div>

        {this.renderLowerFooter()}
      </div>
    );
  }
}
