import { firebase, googleAuthProvider, facebookAuthProvider } from '../firebase/firebase';

export const login = (uid) => ({
  type: 'LOGIN',
  uid,
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const startLogin = () => () => new Promise(
  (resolve) => firebase.auth().signInWithPopup(googleAuthProvider)
    .then(() => {
      resolve();
    }),
  // resolve();
);

// not implemented yet
export const loginFb = (uid) => ({
  type: 'LOGINFB',
  uid,
});

export const startLoginFb = () => () => firebase.auth().signInWithPopup(facebookAuthProvider)
  .then(() => {
    // eslint-disable-next-line no-console
    console.log('Successfully logged onto FB.');
  }).catch((error) => {
    const errorCode = error.code;
    // eslint-disable-next-line no-console
    console.error(errorCode);
  });

export const startLogout = () => () => firebase.auth().signOut().then(() => {
  // eslint-disable-next-line no-console
  console.log('Sign out successful.');
}).catch((error) => {
  // eslint-disable-next-line no-console
  console.log('Error: ', error);
});
