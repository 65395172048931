const analysisInfoDefaultState = {
    lat: 40.7,
    lon: -73.99
}

export default (state = analysisInfoDefaultState, action) => {
    switch(action.type){
        case 'SET_LOCATION':
            return {
                ...state,
                lat: action.obj.lat,
                lon: action.obj.lon
            }
        default:
            return state;
    }
}