import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Header, Footer } from "../components/Common";

export const PrivateRoute = (
  { isAuthenticated, component: Component, ...rest } // contains exact, etc. from App Router
) => (
  <Route
    {...rest}
    component={(props) => {
      if (isAuthenticated) {
        return (
          <div>
            <Header {...props} />
            <Component {...props} />
            <Footer {...props} />
          </div>
        );
      }
      if (
        rest.path === "/getstarted2" ||
        rest.path === "/getstarted3" ||
        rest.path === "/getstarted4" ||
        rest.path === "/getstarted5"
      ) {
        return <Redirect to="/" />;
      }
      return null;
    }}
  />
);

const mapStateToProps = (state) => ({
  isAuthenticated: !!state.auth.uid, // if it exists, u r authed
});

export default connect(mapStateToProps)(PrivateRoute);
