import findProductInventory from "../services/productInventory";

export const addInventory = (obj) => ({
  type: "FETCH_INVENTORY",
  obj,
});

export const startFetchInventory = () => async (dispatch) => {
  const productInventory = await findProductInventory();
  dispatch(addInventory(productInventory));
};
