import React from "react";
import cx from "classnames";
import styles from "./Button.module.scss";

export const Button = ({ className, children, icon, ...props }) => {
  return (
    <button className={cx(styles.btn, className)} {...props}>
      <span>{children}</span>
      {icon ? icon : null}
    </button>
  );
};
